<template>
  <div class="main">
    <div class="header">
      <span class="title">套餐详情</span>
    </div>
    <div class="content">
      <div class="item">
        <div class="title">套餐包含</div>
        <div class="subtitle" v-for="(item,index) in scenicHotelSetmealDetail.itemList" :key="index+'scenicHotelSetmealDetail'">
          <img :src="item.icon" alt="" />{{item.desc}}<span v-if="item.themeElements==1&&item.type==2&&item.itemId"
            @click="jumptoScenic(item)">景点详情</span><span v-if="item.themeElements==1&&item.type==1&&item.itemId"
            @click="jumptoHotel(item)">酒店详情</span>
        </div>
      </div>
      <div class="item">
        <div class="title">套餐说明</div>
        <div :class="showMoreBriefNote?'explainMore':'explain'">
          <div ref="mealDesc">
            {{scenicHotelSetmealDetail.mealDesc}}
          </div>
        </div>
        <div class="moreBtn" @click="moreBriefNote" v-if="!showMoreBriefNote">展开<img src="@/assets/detail/grownDownArrow.png" alt="" /></div>
      </div>
      <div class="item">
        <div class="title">预订须知</div>
        <div :class="showMoreBriefNote1?'explainMore':'explain'">
          <div ref="bookNotice">
            {{scenicHotelSetmealDetail.bookNotice}}
          </div>
        </div>
        <div class="moreBtn" @click="moreBriefNote1" v-if="!showMoreBriefNote1">展开<img src="@/assets/detail/grownDownArrow.png" alt="" /></div>

      </div>
      <div class="item">
        <div class="title">取消政策</div>
        <div class="msg">
          订单生效后，若因买家原因取消订单，买家按以下标准支付违约费用：
        </div>
        <table class="table" rules="cols">
          <tr v-for="(item,index) in scenicHotelSetmealDetail.customRefundRules" :key="index+'customRefundRules'">
            <th>{{item.title}}</th>
            <th>{{item.desc}}</th>
          </tr>
        </table>
        <div class="msg">
          订单生效后，若因卖家原因取消订单，商家不仅退回全款，同时按照一下标准支付违约费用：
        </div>
        <table class="table" rules="cols">
          <tr v-for="(item,index) in scenicHotelSetmealDetail.businessRefundRules" :key="index+'customRefundRules'">
            <th>{{item.title}}</th>
            <th>{{item.desc}}</th>
          </tr>
        </table>
      </div>
      <div class="item">
        <div class="title">费用</div>
        <div class="feiyong">
          <div class="txt">费用不含</div>
          <div class="costExclude">{{scenicHotelSetmealDetail.costExclude}}</div>
        </div>
      </div>
      <div class="tips">- {{scenicHotelSetmealDetail.channelName}} -</div>
    </div>
    <div class="footer">
      <span class="price"> <b>¥</b>{{scenicHotelSetmealDetail.price}}</span>
      <span class="btn" @click="handleBook">预订</span>
    </div>
  </div>
</template>

<script>
import { px2rem } from "@/projects/list/mixins";
import env from "@/utils/env";
import nativeApi from "@/utils/nativeApi";

export default {
  mixins: [px2rem],
  props: {
    scenicHotelSetmealDetail: { type: Object, default: () => ({}) },
  },
  components: {},
  data() {
    return {
      showMoreBriefNote: false,
      showMoreBriefNote1: false,
    };
  },

  created() {
    this.$nextTick(() => {
      if (this.$refs.mealDesc) {
        if (this.$refs.mealDesc.clientHeight < 66) {
          this.showMoreBriefNote = true;
        }
      }
      if (this.$refs.bookNotice) {
        if (this.$refs.bookNotice.clientHeight < 66) {
          this.showMoreBriefNote1 = true;
        }
      }
    });
  },
  methods: {
    moreBriefNote() {
      this.showMoreBriefNote = true;
    },
    moreBriefNote1() {
      this.showMoreBriefNote1 = true;
    },
    //预订
    handleBook() {
      this.$emit("book", this.scenicHotelSetmealDetail);
    },
    jumptoHotel(item) {
      const query = {
        title: item.name,
        hotelId: item.itemId,
      };
      // const page = { path: "/hotel-introduction", query };
      // this.$router.push(page);
      const page = {
        url: "/hotel-introduction",
        name: "hotel-introduction",
        query: query,
        params: {},
      };
      this.$page.push(page);
    },
    jumptoScenic(item) {
      const query = {
        title: item.name,
        scenicSpotId: item.itemId,
      };
      // const page = { path: "/scenic-introduction", query };
      // this.$router.push(page);
      const page = {
        url: "/scenic-introduction",
        name: "scenic-introduction",
        query: query,
        params: {},
      };
      this.$page.push(page);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/_vars.scss";
.main {
  width: 100%;
  max-height: 550 * $px;
  overflow: hidden;
}
.content {
  max-height: 400 * $px;
  padding-top: 17 * $px;
  overflow-y: scroll;
  overflow-x: hidden;
  > .tips {
    width: 100%;
    height: 12 * $px;
    font-size: 12 * $px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    text-align: center;
    color: rgba(52, 56, 75, 0.3);
    line-height: 12 * $px;
    padding-top: 8 * $px;
    padding-bottom: 32 * $px;
  }
}
.header {
  text-align: center;
  height: 57 * $px;
  line-height: 57 * $px;
  border-bottom: 1 * $px solid rgba(52, 56, 75, 0.1);
  .title {
    width: 72 * $px;
    height: 25 * $px;
    opacity: 1;
    font-size: 18 * $px;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 500;
    text-align: center;
    color: #262626;
    line-height: 25 * $px;
  }
}
.item {
  box-sizing: border-box;
  padding-left: 16 * $px;
  padding-right: 16 * $px;
  margin-bottom: 28 * $px;
  .subtitle {
    font-size: 14 * $px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    text-align: left;
    color: #262626;
    display: flex;
    align-items: center;
    // line-height: 16 * $px;
    margin-bottom: 12 * $px;
    img {
      width: 16 * $px;
      height: 16 * $px;
      vertical-align: middle;
      margin-right: 8 * $px;
    }
    span {
      color: #0078ff;
      margin-left: 8 * $px;
    }
    .tips {
      height: 14 * $px;
      font-size: 14 * $px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      text-align: left;
      color: #ff6814;
      line-height: 14 * $px;
      margin-left: 24 * $px;
      margin-top: 9 * $px;
    }
  }
  .subtitle:last-child {
    margin-bottom: 0;
  }
  .title {
    // width: 72 * $px;
    height: 18 * $px;
    font-size: 18 * $px;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 500;
    text-align: left;
    color: #262626;
    line-height: 18 * $px;
    margin-bottom: 16 * $px;
  }
  .explain {
    height: 66 * $px;
    font-size: 14 * $px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    text-align: left;
    color: rgba(52, 56, 75, 0.75);
    line-height: 22 * $px;
    overflow: hidden;
    text-overflow: ellipsis;
    // display: -webkit-box;
    // -webkit-line-clamp: 3;
    // -webkit-box-orient: vertical;
  }
  .explainMore {
    font-size: 14 * $px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    text-align: left;
    color: rgba(52, 56, 75, 0.75);
    line-height: 22 * $px;
  }
  .moreBtn {
    margin-top: 6 * $px;
    height: 14 * $px;
    font-size: 14 * $px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    text-align: center;
    color: rgba(52, 56, 75, 0.5);
    line-height: 14px;
    img {
      width: 10 * $px;
      height: 6 * $px;
      display: inline-block;
    }
  }
  .msg {
    font-size: 13 * $px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    text-align: left;
    color: #262626;
    line-height: 18 * $px;
    margin-bottom: 8 * $px;
  }
  .feiyong {
    height: 14 * $px;
    font-size: 14 * $px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    display: flex;
    .txt {
      width: 88 * $px;
      color: rgba(52, 56, 75, 0.75);
    }
    .costExclude {
      width: 255 * $px;
      color: #262626;
      // margin-left: 32 * $px;
    }
  }
}
.table {
  width: 100%;
  border-color: #d8d8d8;
  border-top: 0.5 * $px solid #d8d8d8;
  border-bottom: 0.5 * $px solid #d8d8d8;
  margin-bottom: 17 * $px;
  tr {
    th {
      font-size: 13 * $px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      text-align: left;
      color: #262626;
      text-align: center;
      border-bottom: 0.5 * $px solid #d8d8d8;
    }
  }
}
.footer {
  width: 100%;
  height: 64 * $px;
  background: #fff;
  box-shadow: 0 * $px 0 * $px 0 * $px 0 * $px #ebebeb;
  padding: 10 * $px 16 * $px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  .price {
    font-size: 24 * $px;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 500;
    text-align: left;
    color: #ff6814;
    b {
      font-size: 14 * $px;
      font-weight: normal;
    }
  }
  .btn {
    width: 246 * $px;
    height: 44 * $px;
    background: linear-gradient(90deg, #ff9040, #ff5e19);
    border-radius: 22 * $px;
    font-size: 18 * $px;
    font-family: PingFangSC;
    font-weight: 400;
    text-align: center;
    color: #ffffff;
    line-height: 44 * $px;
  }
}
</style>
