<template>
  <loading :loading="isLoading">
    <div class="main">
      <div class="nav-bar">
        <div></div>
        <div class="content" @click="goBack()" v-if="!['sinopec','wxapp-hbgj','boe'].includes(hlsource)">
          <img src="@/assets/detail/hotel-scenic/nav-back.png" alt="" srcset="" />
        </div>
      </div>
      <swipe-comp :ads="productDetail.images" class="swipe-comp" :show="swipeShow" :axiosObj='axiosObj' :showPhotoList='true' :typeOfBusiness='3' />
      <div class="content">
        <div class="card-detail">
          <div>
            <span class="title">{{productDetail.productName}}</span>
            <span class="time">{{productDetail.day}}天{{productDetail.night}}晚</span>
            <!-- <span class="detail">详情<img src="@/assets/detail/hotel-scenic/arrow-blue.png" alt="" /></span> -->
          </div>
          <div>
            <span v-for="(item,index) in productDetail.highlights" :key='index+100'>{{item}}</span>
          </div>
        </div>
        <div class="ticket-list">
          <div class="dateSelect">
            <div v-for="(item,index) in priceDatePopup" :key="index+'priceDatePopup'" @click="selectDate(item, index+1)"
              :class="nowday == (index+1) ? 'item select' : 'item'">
              {{dateTransform(item.saleDate)}}
            </div>
            <!-- <div :class="nowday == 1 ? 'item select' : 'item'" @click="selectDate(TODAY, 1)">
              今日
            </div>
            <div :class="nowday == 2 ? 'item select' : 'item'" @click="selectDate(NEXTDAY, 2)">
              明日
            </div> -->
            <div class="item" @click="showDatePrice">更多日期</div>
          </div>
          <div class="ticket-item" @click="openSetmeal(item,index)" v-for="(item,index) in scenicSetmealList" :key="index+'scenicSetmealList'">
            <div>
              <div class="title">{{item.title}}</div>
              <div class="subtitle" v-for="(v,i) in item.items" :key="i+'v'">
                <img :src="v.icon" alt="" />{{v.itemDesc}}
              </div>
            </div>
            <div class="priceBox">
              <div class="price"><b>¥</b>{{item.price}}</div>
              <div class="btn" @click.stop="handleBook(item)">预订</div>
            </div>
          </div>
        </div>
        <div class="productDetail-desc">
          <div class="title">图文详情</div>
          <div class="desc" v-html="productDetail.desc"></div>
        </div>
      </div>
      <hl-popup v-model="showSetMeal" position="bottom" closeable>
        <set-meal-detail class="set-meal-detail" :scenicHotelSetmealDetail='scenicHotelSetmealDetail' @book='handleBook'></set-meal-detail>
      </hl-popup>
      <date-price ref="datePriceRef" :query="axiosObj" @confirm="datePriceHandleConfirm" />
    </div>
  </loading>

</template>
<script>
// import { px2rem } from "@/projects/list/mixins";
import swipeComp from "../components/swipe";
import setMealDetail from "./components/set-meal-detail";
import datePrice from "@/projects/detail/components/date-price";
import moment from "moment";
import env from "@/utils/env";
import nativeApi from "@/utils/nativeApi";
import service from "@/service";
import get from "@/utils/get";
import loading from "@/components/c-loading.vue";
import { Toast } from "huoli-ui";

export default {
  //   mixins: [px2rem],
  components: {
    swipeComp,
    setMealDetail,
    datePrice,
    loading,
  },
  data() {
    return {
      hlsource: env.hlsource,

      priceDatePopup: [],
      isLoading: true,
      swipeShow: false,
      //价格日历请求参数
      axiosObj: {
        productId: this.$route.query.productId,
        packageId: "",
        startDate: "",
        endDate: "",
        pid: "215052",
      },
      nowday: 1,
      TODAY: moment().format("YYYY-MM-DD"),
      NEXTDAY: moment().add(1, "d").format("YYYY-MM-DD"),
      showSetMeal: false,
      selectDateQuery: moment().format("YYYY-MM-DD"),
      //酒景套餐产品详情数据接口说明215050
      productDetail: {
        // //产品ID
        // productId: "29",
        // //产品名称
        // productName: "单房+单票产品",
        // //亮点
        // highlights: ["五星", "自主安排"],
        // //图片列表
        images: [
          //   "https://qiniu-cdn0.jinxidao.com/group1/M00/0C/13/Cg0Ohl-hFoGABWjMAAjue31zPzc874.png",
        ],
        // //图文详情
        // desc: "<p>对外说明-测试</p>\n",
        // day: 0,
        // night: 0,
        // //产品类目
        // category: "hotel_scenicSpot",
      },
      //酒景套餐产品套餐列表数据接口215051
      scenicSetmealList: [
        // {
        //   //套餐ID
        //   packageId: "36",
        //   //产品ID
        //   productId: "29",
        //   //套餐名称
        //   title: "单房+单票产品",
        //   //套餐价格
        //   price: 300.0,
        //   //元素列表
        //   items: [
        //     {
        //       itemType: null,
        //       itemId: null,
        //       itemName: null,
        //       //元素描述
        //       itemDesc: "分销酒景  - 单房 0晚",
        //       //图标
        //       icon: "https://cdn.rsscc.cn/ticket/images/hbgj_icon_%20live_0519@3x.png",
        //     },
        //     {
        //       itemType: null,
        //       itemId: null,
        //       itemName: null,
        //       itemDesc: "分销酒景  - 单票 1张",
        //       icon: "https://cdn.rsscc.cn/ticket/images/hbgj_icon_scene_0519@3x.png",
        //     },
        //   ],
        // },
      ],
      //酒景套餐产品套餐详情215053
      scenicHotelSetmealDetail: {
        // //产品ID
        // productId: "29",
        // //产品名称
        // packageId: "36",
        // //元素列表
        // itemList: [
        //   {
        //     //元素描述
        //     desc: "分销酒景 - 单房 0晚",
        //     //是否主元素 1是需要展示跳转入口
        //     themeElements: 1,
        //     //元素ID
        //     itemId: "35",
        //     //元素类型 1：跳转酒店详情 2：跳转景点详情 其余忽略
        //     type: 1,
        //   },
        //   {
        //     desc: "分销酒景 - 单票 1张",
        //     themeElements: 0,
        //     itemId: null,
        //     type: null,
        //   },
        // ],
        // //套餐说明
        // mealDesc:
        //   "一年一度的樱花节战役，随着天暖放晴“高调”打响。自从2009年武广高铁开通，一批批高铁客成为武汉赏樱主力军。具有浓厚人文气息的武大，自然是赏樱花…",
        // //预订须知
        // bookNotice:
        //   "酒店描述文案樱园位于东湖梅园近旁的国家5A景区东湖磨山景区南麓，占地260亩，有樱花树10000株。园内种植的第一批樱花由日本前首相田中角荣赠送给…",
        // //费用不含
        // costExclude: null,
        // //卖家违约规则
        // businessRefundRules: [
        //   {
        //     //标题
        //     title: "出发前",
        //     //描述
        //     desc: "30%",
        //   },
        // ],
        // //买家违约规则，结构同卖家
        // customRefundRules: [
        //   {
        //     //标题
        //     title: "出发前",
        //     //描述
        //     desc: "30%",
        //   },
        // ],
        // //动态说明
        // bookNotices: [
        //   {
        //     changedFields: null,
        //     title: "费用不包含",
        //     content: "单房+单票产品",
        //   },
        // ],
        // //渠道名称
        // channelName: "要出发",
        // channel: "yaochufa",
        // price: "300",
        // productName: "单房+单票产品",
      },
      //酒景产品价格日历215052
    };
  },
  methods: {
    dateTransform(saleDate) {
      if (moment(saleDate).isSame(this.TODAY)) {
        return `今天`;
      } else if (moment(saleDate).isSame(this.NEXTDAY)) {
        return `明天`;
      } else {
        return moment(saleDate).format("MM-DD");
      }
    },
    //预订
    handleBook(item) {
      // console.log(item);
      if (env.isNative) {
        nativeApi.invoke("createWebView", {
          url: `${
            process.env.VUE_APP_BASE_URL_PAYMENT
          }/hangban/vue/hltrip/bookV2?analyseSourceEntry=${
            env.hlsource
          }&title=${null}&productId=${item.productId}&packageId=${
            item.packageId
          }&productType=1&startDate=${
            this.selectDateQuery
          }&category=hotel_scenicSpot`,
        });
      } else {
        // const a = document.createElement("a");
        // a.target = "_blank";
        location.href = `${
          process.env.VUE_APP_BASE_URL_PAYMENT
        }/hangban/vue/hltrip/bookV2?analyseSourceEntry=${
          env.hlsource
        }&title=${null}&productId=${item.productId}&packageId=${
          item.packageId
        }&productType=1&startDate=${
          this.selectDateQuery
        }&category=hotel_scenicSpot`;
        // a.click();
      }

      // location.href =;
      // console.log("预订");
      // if (this.selectDate) {
      //   console.log("预订");
      //   //     axiosObj: {
      //   //   packageId: "",
      //   //   startDate: "",
      //   //   endDate: "",
      //   //   productId: this.$route.query.productId,
      //   //   pid: "215042",
      //   // },
      //   location.href = `https://wtest.133.cn/hangban/vue/hltrip/book?hlsource=${
      //     env.hlsource
      //   }&title=${null}&productId=${this.axiosObj.productId}&packageId=${
      //     this.axiosObj.packageId
      //   }&productType=3&startDate=${this.selectDate}&category=group_tour`;
      // } else {
      //   this.isBook = true;
      //   this.showDatePrice();
      // }
    },
    goBack() {
      // this.$router.back();
      if (env.hlsource === "sinopec") {
        window.location.href = "backtonative.html";
        return;
      } else {
        this.$page.back();
      }
    },

    //查看产品详情
    openSetmeal(item, index) {
      Toast.loading({
        duration: 0,
        message: "加载中...",
        forbidClick: true,
      });
      this.showSetMeal = !this.showSetMeal;
      console.log(item);
      this.getScenicHotelSetmealDetail(item.productId, item.packageId).then(
        (data) => {
          this.scenicHotelSetmealDetail = data;
          this.scenicHotelSetmealDetail.price =
            this.scenicSetmealList[index].price;
          Toast.clear();

          console.log(this.scenicHotelSetmealDetail);
        }
      );
    },
    //选择日期
    selectDate(res, index) {
      this.nowday = index;
      //   console.log(res);
      let obj = {
        dateRes: [res.saleDate],
      };
      this.datePriceHandleConfirm(obj);
    },
    //时间选择器确认
    datePriceHandleConfirm(res) {
      Toast.loading({
        duration: 0,
        message: "加载中...",
        forbidClick: true,
      });
      this.selectDateQuery = res.dateRes[0];

      if (res.dateRes[0] == this.TODAY) {
        this.nowday = 1;
        this.getScenicSetmealList(this.axiosObj.productId, res.dateRes[0]).then(
          (data) => {
            this.scenicSetmealList = data || [];
            Toast.clear();
          }
        );
      } else if (res.dateRes[0] == this.NEXTDAY) {
        this.nowday = 2;
        this.getScenicSetmealList(this.axiosObj.productId, res.dateRes[0]).then(
          (data) => {
            this.scenicSetmealList = data || [];
            Toast.clear();
          }
        );
      } else {
        this.nowday = 3;
        this.getScenicSetmealList(this.axiosObj.productId, res.dateRes[0]).then(
          (data) => {
            this.scenicSetmealList = data || [];
            Toast.clear();
          }
        );
      }
    },
    //点击更多日期
    showDatePrice() {
      this.$refs.datePriceRef.open({
        from: "more-price",
        // date: this.date,
        // productCode: this.productCode,
        // packageCode: this.packageCode,
        // productType: Number(this.productType),
      });
    },
    //酒景套餐产品详情数据接口说明215050
    getProductDetail(productId) {
      let obj = {
        productId: productId,
      };
      return service.r215050(obj).then((res) => get(res, "res.bd.data", []));
    },
    //酒景套餐产品套餐列表数据接口215051
    getScenicSetmealList(productId, startDate) {
      let obj = {
        productId: productId,
        startDate: startDate,
      };
      return service.r215051(obj).then((res) => get(res, "res.bd.data", []));
    },
    //酒景套餐产品套餐详情215053
    getScenicHotelSetmealDetail(productId, packageId) {
      let obj = {
        productId: productId,
        packageId: packageId,
      };
      return service.r215053(obj).then((res) => get(res, "res.bd.data", []));
    },
    //价格日历215052
    getPriceDatePopup(productId, packageId, startDate, endDate) {
      let obj = {
        productId: productId,
        packageId: packageId,
        startDate: startDate,
        endDate: endDate,
      };
      return service.r215052(obj).then((res) => get(res, "res.bd.data", []));
    },
  },
  beforeCreate() {
    if (env.isNative) {
      nativeApi.invoke("updateHeaderRightBtn", {
        action: "hide",
        icon: "",
        text: "",
        data: {},
      });
      nativeApi.invoke("setNavigationBarVisible", { value: "0" });
      nativeApi.invoke("setNavigationBarTintColor", { statusBarStyle: "0" });
    }
  },
  created() {
    Promise.all([
      this.getProductDetail(this.axiosObj.productId),
      this.getScenicSetmealList(this.axiosObj.productId, ""),
      this.getPriceDatePopup(this.axiosObj.productId, "", "", ""),
    ]).then((data) => {
      this.productDetail = data[0];
      this.scenicSetmealList = data[1];
      // this.axiosObj.packageId = this.scenicSetmealList[0];
      // console.log(data[2]).
      if (data[2].priceInfos) {
        this.priceDatePopup =
          data[2].priceInfos.filter((item, index) => index < 2) || [];
      } else {
        this.priceDatePopup = [];
      }

      this.isLoading = false;
      this.swipeShow = true;
    });
  },
};
</script>
<style lang="scss" scoped>
@import "@/scss/_vars.scss";
.main {
  width: 100%;
  height: 100%;
  background-color: #f2f4f7;
  .swipe-comp {
    overflow: hidden;
  }
  .nav-bar {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9;
    div:first-child {
      height: 44 * $px;
    }
    .content {
      height: 44 * $px;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-left: 12 * $px;
      > img {
        width: 28 * $px;
        height: 28 * $px;
      }
    }
  }
  > .content {
    position: relative;
    top: 0;
    bottom: 0;
    margin-top: -15 * $px;
    width: 100%;
  }
}
.card-detail {
  box-sizing: border-box;
  width: 359 * $px;
  background: #ffffff;
  margin: 0 auto;
  border-radius: 12 * $px;
  padding: 15 * $px 11 * $px 16 * $px 12 * $px;
  margin-bottom: 8 * $px;
  > div:first-child {
    margin-bottom: 14 * $px;
    .title {
      width: 153 * $px;
      height: 17 * $px;
      opacity: 1;
      font-size: 17 * $px;
      font-family: PingFangSC, PingFangSC-Medium;
      font-weight: 500;
      text-align: left;
      color: #262626;
      line-height: 17 * $px;
      margin-right: 8 * $px;
    }
    .time {
      width: 39 * $px;
      height: 12 * $px;
      opacity: 1;
      font-size: 12 * $px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      text-align: left;
      color: rgba(52, 56, 75, 0.75);
      line-height: 12 * $px;
    }
    .detail {
      float: right;
      height: 17 * $px;
      font-size: 12 * $px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      text-align: right;
      color: #0078ff;
      line-height: 17 * $px;

      img {
        width: 4 * $px;
        height: 7 * $px;
        display: inline-block;
        margin-left: 6 * $px;
      }
    }
  }
  > div:nth-child(2) {
    width: 335 * $px;
    height: 36 * $px;
    opacity: 1;
    font-size: 12 * $px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    text-align: left;
    color: #262626;
    line-height: 18 * $px;
  }
}
.ticket-list {
  box-sizing: border-box;
  width: 359 * $px;
  //   height: 380 * $px;
  background: #ffffff;
  margin: 0 auto;
  border-radius: 12 * $px;
  margin-bottom: 8 * $px;
  padding: 20 * $px 12 * $px 16 * $px 12 * $px;
  //   > div:nth-child(1) {
  //     margin-bottom: 16 * $px;
  //     > span {
  //       background: #f8f9fb;
  //       border-radius: 17 * $px;
  //       padding: 6 * $px 12 * $px;
  //       font-size: 13 * $px;
  //       font-family: PingFangSC, PingFangSC-Medium;
  //       font-weight: 500;
  //       text-align: center;
  //       color: #262626;
  //       margin-right: 8 * $px;
  //     }
  //     > .current {
  //       background: #ebf5ff;
  //       color: #0078ff;
  //     }
  //   }
  .dateSelect {
    display: flex;
    margin-bottom: 16 * $px;
    .item {
      background: #f8f9fb;
      border-radius: 17 * $px;
      padding: 6 * $px 12 * $px;
      font-size: 13 * $px;
      font-family: PingFangSC, PingFangSC-Medium;
      font-weight: 400;
      margin-right: 8 * $px;
      color: #262626;
    }
    .select {
      background: #ebf5ff;
      color: #0078ff;
      font-weight: 500;
    }
  }
  > .ticket-item {
    box-sizing: border-box;
    width: 335 * $px;
    // position: relative;
    display: flex;
    align-items: center;
    // height: 100%;
    // height: 132 * $px;
    background: linear-gradient(
      180deg,
      rgba(0, 176, 255, 0.05),
      rgba(141, 194, 255, 0.05)
    );
    border-radius: 8 * $px;
    padding-left: 16 * $px;
    padding-right: 8 * $px;
    margin-bottom: 8 * $px;
    padding-bottom: 18 * $px;
    padding-top: 16 * $px;
    > div:nth-child(1) {
      //   width: 232 * $px;
      //   float: left;
      > .title {
        // width: 232 * $px;
        // height: 42 * $px;
        opacity: 1;
        font-size: 15 * $px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        text-align: left;
        color: #262626;
        line-height: 21 * $px;
        // margin-top: 16 * $px;
        margin-bottom: 16 * $px;
      }
      > .subtitle {
        margin-right: 18 * $px;
        width: 213 * $px;
        // height: 16 * $px;
        font-size: 12 * $px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        text-align: left;
        color: rgba(52, 56, 75, 0.75);
        margin-bottom: 8 * $px;
        display: flex;
        // align-items: center;
        img {
          width: 16 * $px;
          height: 16 * $px;
          margin-right: 8 * $px;
          margin-top: 1 * $px;
        }
      }
      > .subtitle:last-child {
        margin-bottom: 0;
      }
    }
    > .priceBox {
      //   float: right;
      //   position: absolute;
      //   top: 50%; /*偏移*/
      // //   transform: translateY(50%);
      //   //   width: 100%;
      //   //   height: 100%;
      //   right: 8 * $px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .price {
        width: 41 * $px;
        height: 24 * $px;
        font-size: 20 * $px;
        font-family: PingFangSC, PingFangSC-Medium;
        font-weight: 500;
        text-align: right;
        color: #ff6814;
        line-height: 17 * $px;
        margin-bottom: 4 * $px;
        b {
          font-size: 11 * $px;
        }
      }
      .btn {
        width: 60 * $px;
        height: 28 * $px;
        background: linear-gradient(90deg, #ff9040, #ff5e19);
        border-radius: 16 * $px;
        width: 60 * $px;
        font-size: 14 * $px;
        font-family: PingFangSC, PingFangSC-Medium;
        font-weight: 500;
        text-align: center;
        color: #ffffff;
        line-height: 28 * $px;
      }
    }
  }
}
.productDetail-desc {
  padding: 16 * $px 12 * $px;
  background: #ffffff;
  border-radius: 12 * $px;
  margin-left: 8 * $px;
  .title {
    font-size: 18 * $px;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 500;
    // text-align: left;
    color: #262626;
  }
}
</style>
